import { Rpc } from '@/models/Rpc';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { AxiosError, AxiosResponse } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { DateTime } from 'luxon';
import { StatisticsColumn } from '@/components/week-statistics-view/WeekStatisticsView';
import { planningStatusLabelMap } from '@/support/ReportStatus';
import { DateFormatter } from '@/support/DateFormatter';
import { StatisticsItem } from '@/components/statistics-view/StatisticsView';

@Component<PlanningTypeStatistics>({})
export default class PlanningTypeStatistics extends Vue {
  public $pageTitle = 'Productie per type';

  protected isLoading = true;

  protected types: {[key: string]: any}[] | null = null;

  protected date = new DateFormatter();

  // columns
  protected columns: StatisticsColumn[] = [
    {
      key: 'name',
      name: 'Type',
      class: 'xs4 md2 lg4',
      transform: (item: StatisticsItem) => {
        if (! item) { return ''; }

        return planningStatusLabelMap[item.name];
      },
    },
    {
      key: 'week',
      name: 'Maandag',
      class: 'xs1 text-right',
    },
    {
      key: 'week',
      name: 'Dinsdag',
      class: 'xs1 text-right',
    },
    {
      key: 'week',
      name: 'Woensdag',
      class: 'xs1 text-right',
    },
    {
      key: 'week',
      name: 'Donderdag',
      class: 'xs1 text-right',
    },
    {
      key: 'week',
      name: 'Vrijdag',
      class: 'xs1 text-right',
    },
    {
      key: 'week',
      name: 'Zaterdag',
      class: 'xs1 text-right',
    },
    {
      key: 'week',
      name: 'Zondag',
      class: 'xs1 text-right',
    },
    {
      key: 'row_total',
      name: 'Totaal',
      class: 'xs1 text-right',
    },
  ];

  public mounted() {
    this.date.selectedDate = DateTime.local().toFormat('yyyy-LL-dd');
    this.emitBreadcrumb();
    this.initialize();
  }

  protected async initialize() {
    await this.fetchTypes();
  }

  protected async fetchTypes() {
    this.isLoading = true;

    const payload = {
      signature: 'planning:cancelled-statistics',
      body: {
        date: this.date.selectedDate,
        mode: 'week',
        category: 'normal',
      },
    };

    await new Rpc()
      .rpcPost(payload, false)
      .then((response: AxiosResponse) => {
        this.types = response.data;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    this.isLoading = false;
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Productie per type' },
        ],
      });
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }
}
